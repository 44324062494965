
import { defineComponent } from "vue";
export default defineComponent({
  name: "MPanel",
  props: ["tabs"],
  methods: {
    isLastTab(current: number) {
      return current === this.tabs.length - 1;
    }
  },
});
