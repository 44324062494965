import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    icon: "pi pi-arrow-left",
    iconPos: "left",
    class: "mt-4 p-0 vp-font-small p-button-link font-bold vp-variant",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$router.back())),
    label: "Back"
  }))
}