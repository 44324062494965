import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "grid align-items-center" }
const _hoisted_3 = { class: "col-2" }
const _hoisted_4 = { class: "col-10" }
const _hoisted_5 = { class: "grid mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_back = _resolveComponent("a-back")!
  const _component_a_hgroup = _resolveComponent("a-hgroup")!
  const _component_m_panel = _resolveComponent("m-panel")!
  const _component_input_switch = _resolveComponent("input-switch")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_back),
    _createVNode(_component_a_hgroup, { heading: "Manage Volunteer Registration" }),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_m_panel, { tabs: _ctx.tabs }, null, 8, ["tabs"])
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_input_switch, {
          modelValue: _ctx.v$.isEnabled.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.isEnabled.$model) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t(`management.description.${_ctx.type}`)), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createVNode(_component_Button, {
        disabled: !_ctx.isEnabled,
        loading: _ctx.isLoading,
        onClick: _ctx.removeData,
        class: "vp-button w-full font-bold m-2",
        label: _ctx.$t(`cta.manage`)
      }, null, 8, ["disabled", "loading", "onClick", "label"])
    ])
  ], 64))
}