
import { defineComponent, ref } from "vue";
import AHgroup from "@/components/atoms/a-hgroup.vue";
import { useRoute } from "vue-router";
import ABack from "@/components/atoms/a-back.vue";
import MPanel from "@/components/molecules/m-panel.vue";
import GroupIcon from "@/assets/user-group.svg";
import UserIcon from "@/assets/user.svg";
import { useMsal } from "@/composition-api/useMsal";
import {
  deleteRegistration,
  getRegistration,
  RegistrationResponse,
} from "@/services/user.service";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { toastSettings } from "@/helpers/registration";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";

interface Tab {
  name: string;
  icon: string;
  key: string;
}

export default defineComponent({
  components: {
    AHgroup,
    ABack,
    MPanel,
  },
  methods: {
    async removeData() {
      try {
        const id = this.$route.params.id as string;
        this.isLoading = true;
        const response = await deleteRegistration(id);
        if (!response) {
          throw new Error();
        }
        this.$router.push(`/${this.$i18n.locale}`);
      } catch {
        this.$toast.add(toastSettings());
      } finally {
        this.isLoading = false;
      }
    },
    async setRegistrationData() {
      const response = await getRegistration();
      this.tabs = this.setTabs(response);
    },
    setTabs(data: RegistrationResponse[]) {
      return data
        .filter(({ _id }) => {
          return _id === (this.$route.params.id as string);
        })
        .map((tab) => {
          const isSingle = tab.workFlow === "individual";
          const key = isSingle ? tab.user.id : tab?.organisation?.id ?? "";
          const name = isSingle ? tab.user.name : tab?.organisation?.name;
          return {
            key: key,
            icon: isSingle ? UserIcon : GroupIcon,
            name: name ?? "",
          };
        });
    },
  },
  data() {
    return {
      isEnabled: false,
    };
  },
  validations() {
    return {
      isEnabled: {
        required,
      },
    };
  },
  mounted() {
    // TODO: Place all data into store and use dispatchers
    this.setRegistrationData();
  },
  setup() {
    const isAuthenticated = useIsAuthenticated();
    const { params } = useRoute();
    const isLoading = ref(false);
    const v$ = useVuelidate();
    const tabs = ref<Tab[]>([]);

    return {
      v$,
      isAuthenticated,
      isLoading,
      tabs,
      type: params.type,
    };
  },
});
